s-modal {
  /* modals are hidden by default */
  display: none;

  .s-modal {
    /* modal container fixed across the whole screen */
    position: fixed; /* Use fixed to prevent the modal from moving */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 910;
    max-width: 90vw; /* Adjust width for screens */
    max-height: 85vh; /* Adjust height to make it scrollable */
    overflow: hidden; /* Prevent modal itself from scrolling */
    background-color: #fff;
    border-radius: 8px; /* Rounded corners */
  }

  .s-modal-body {
    padding: 10px; /* Add some padding to the content */
    background-color: #fff;
    overflow-y: auto; /* Enable scrolling inside the modal body */
    max-height: calc(85vh - 40px); /* Ensure modal body can scroll */
  }

  .s-modal-background {
    /* Modal background fixed across the whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent black */
    z-index: 900;
  }
}

pf-s-modal {
  /* modals are hidden by default */
  display: none;

  .s-modal {
    /* modal container fixed across the whole screen */
    position: fixed; /* Use fixed to prevent the modal from moving */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 910;
    max-width: 90vw; /* Adjust width for screens */
    max-height: 85vh; /* Adjust height to make it scrollable */
    overflow: hidden; /* Prevent modal itself from scrolling */
    background-color: #fff;
    border-radius: 8px; /* Rounded corners */
  }

  .s-modal-body {
    padding: 10px; /* Add some padding to the content */
    background-color: #fff;
    overflow-y: auto; /* Enable scrolling inside the modal body */
    max-height: calc(85vh - 40px); /* Ensure modal body can scroll */
  }

  .s-modal-background {
    /* Modal background fixed across the whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75); /* Semi-transparent black */
    z-index: 900;
  }
}

/* Ensuring the modal is centered and responsive on mobile and tablet screens */
@media (max-width: 768px) {
  s-modal .s-modal,
  pf-s-modal .s-modal {
    max-width: 90vw;
    max-height: 85vh;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden; /* Prevent modal container from scrolling */
  }

  .s-modal-body {
    padding: 10px;
    max-height: 75vh; /* Limit height for better mobile experience */
    overflow-y: auto; /* Enable content scrolling inside the modal */
  }
}

/* Prevent body from scrolling when modal is open */
body.s-modal-open {
  overflow: hidden;
}
