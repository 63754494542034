/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */
:root {
    color-scheme: light;
    --pf: 258.89 94.378% 40.941%;
    --sf: 314 100% 37.647%;
    --af: 174 60% 40.784%;
    --nf: 219 14.085% 22.275%;
    --in: 198 93% 60%;
    --su: 158 64% 52%;
    --wa: 43 96% 56%;
    --er: 0 91% 71%;
    --inc: 198 100% 12%;
    --suc: 158 100% 10%;
    --wac: 43 100% 11%;
    --erc: 0 100% 14%;
    --rounded-box: 1rem;
    --rounded-btn: .5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: .25s;
    --animation-input: .2s;
    --btn-text-case: uppercase;
    --btn-focus-scale: .95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: .5rem;
    --p: 258.89 94.378% 51.176%;
    --pc: 0 0% 100%;
    --s: 314 100% 47.059%;
    --sc: 0 0% 100%;
    --a: 174 60% 50.98%;
    --ac: 174.71 43.59% 15.294%;
    --n: 219 14.085% 27.843%;
    --nc: 0 0% 100%;
    --b1: 0 0% 100%;
    --b2: 0 0% 94.902%;
    --b3: 180 1.9608% 90%;
    --bc: 215 27.907% 16.863%;
}
/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import '~quill/dist/quill.snow.css';

/* Toastr */
@import 'node_modules/ngx-toastr/toastr';
.toast-container .ngx-toastr {
    border-radius: 10px !important;
    .toast-close-button {
        color: hsl(var(--suc,var(--nc))/var(--tw-text-opacity)) !important;
    }
}
.toast-success {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--su)/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--suc,var(--nc))/var(--tw-text-opacity)) !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSIgZmxleC1zaHJpbmstMCBoLTYgdy02IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMTMzMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik05IDEybDIgMiA0LTRtNiAyYTkgOSAwIDExLTE4IDAgOSA5IDAgMDExOCAweiI+PC9wYXRoPjwvc3ZnPgo=);
}
.toast-info {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--in)/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--inc,var(--nc))/var(--tw-text-opacity)) !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3Ryb2tlPSIjMDEyYjNkIiBjbGFzcz0iIGZsZXgtc2hyaW5rLTAgdy02IGgtNiI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMyAxNmgtMXYtNGgtMW0xLTRoLjAxTTIxIDEyYTkgOSAwIDExLTE4IDAgOSA5IDAgMDExOCAweiI+PC9wYXRoPjwvc3ZnPgo=);
}
.toast-warning {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--wa)/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--wac,var(--nc))/var(--tw-text-opacity)) !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSIgZmxleC1zaHJpbmstMCBoLTYgdy02IiBzdHJva2U9IiMzODI4MDAiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMiA5djJtMCA0aC4wMW0tNi45MzggNGgxMy44NTZjMS41NCAwIDIuNTAyLTEuNjY3IDEuNzMyLTNMMTMuNzMyIDRjLS43Ny0xLjMzMy0yLjY5NC0xLjMzMy0zLjQ2NCAwTDMuMzQgMTZjLS43NyAxLjMzMy4xOTIgMyAxLjczMiAzeiI+PC9wYXRoPjwvc3ZnPgo=);
}
.toast-error {
    --tw-bg-opacity: 1;
    background-color: hsl(var(--er)/var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: hsl(var(--erc,var(--nc))/var(--tw-text-opacity)) !important;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGNsYXNzPSIgZmxleC1zaHJpbmstMCBoLTYgdy02IiBzdHJva2U9IiM0NzAwMDEiIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xMCAxNGwyLTJtMCAwbDItMm0tMiAybC0yLTJtMiAybDIgMm03LTJhOSA5IDAgMTEtMTggMCA5IDkgMCAwMTE4IDB6Ij48L3BhdGg+PC9zdmc+Cg==);
}
