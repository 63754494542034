/* ----------------------------------------------------------------------------------------------------- */
/*  @ Main Tailwind file for injecting utilities.
/* ----------------------------------------------------------------------------------------------------- */
@tailwind utilities;

* {
    scrollbar-width: thin;
    scrollbar-color: rgb(4, 4, 4);
  }

  *::-webkit-scrollbar {
    width: 2px;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 10px;
  }
  
  *::-webkit-scrollbar-thumb {
    // background-color: white;
    border-radius: 20px;
    // border: 1px solid gray;
  }