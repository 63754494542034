/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "~@angular/material/theming";
// @include mat-core();
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "~ng-pick-datetime/assets/style/picker.min.css";

.video-background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content-containerss {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Add a semi-transparent background for readability */
    color: #fff; /* Set text color to white or your preferred color */
    padding: 20px;
  }
  
body {
    --graded: #ff7c00;
    --gradec: #ffc000;
    --gradeb: #c5e0b3;
    --gradea: #92d050;
    --grade: #82f802;
    --critical: #B33D36;
    --high: #EB973E;
    --medium: #FCE94E;
    --low: #6fb8f9;
    --info: #97d7ff;
    --not-applicable: #6d6d6d;
    --compliant: #61AD52;
    --p: 258.89 94.378% 51.176%;
    --pc: 0 0% 100%;
    --s: 314 100% 47.059%;
    --sc: 0 0% 100%;
    --a: 174 60% 50.98%;
    --ac: 174.71 43.59% 15.294%;
    --n: 219 14.085% 27.843%;
    --nc: 0 0% 100%;
    --b1: 0 0% 100%;
    --b2: 0 0% 94.902%;
    --b3: 180 1.9608% 90%;
    --bc: 215 27.907% 16.863%;
}

.backg{
    color: #fff;
    font-family: "Segoe UI", sans-serif;
    z-index: 0;
    // background: var(--petrolbunk-image)  no-repeat center center;
    background-size: cover;
    background-color: #0d162d !important;
    background-blend-mode: multiply;
}

.bgggg{
    // background-image: linear-gradient(360deg, #53a69a 25%, #3781ad 100%);
    background: url('/assets/HamadMedicalCorporation.jpg') no-repeat;
    background-size: cover;
    // background-color: var(--fuse-primary) !important;
    background-blend-mode: multiply;
}

.bg-overlay {
    background: url('/assets/background.jpg') no-repeat;
    z-index: 0;
    background-size: cover;
    // background-color: #242322;
    background-color: var(--fuse-primary) !important;
    background-blend-mode: multiply;

  }

.t-gray {
    color: var(--gray);
}

.t-c {
    color: var(--critical);
}

.t-h {
    color: var(--high);
}

.t-m {
    color: var(--medium);
}

.t-l {
    color: var(--low);
}

.t-i {
    color: var(--info);
}

.t-d {
    color: var(--not-applicable);
}

.t-g {
    color: var(--compliant);
}

.gradef, .gradef:hover {
    color: #fff !important;
    background-color: var(--gradef) !important
}

.graded, .graded:hover {
    color: #444444 !important;
    background-color: var(--graded) !important
}

.gradec, .gradec:hover {
    color: #444444 !important;
    background-color: var(--gradec) !important
}

.gradeb, .gradeb:hover {
    color: #444444 !important;
    background-color: var(--gradeb) !important
}

.gradea, .gradea:hover {
    color: #444444 !important;
    background-color: var(--gradea) !important
}

.grade, .grade:hover {
    color: #444444 !important;
    background-color: var(--gradea) !important
}

.spacer {
    flex: 1 1 auto;
}

.mini-form-field {transform: translateY(0em) scale(0.8) !important;}

.imageWidth {
    width: 17px !important;
    margin-left: 9px;
}

.imageWidthDate {
    width: 25px !important;
    margin-left: 5px;
}

.vendor-logo {
    width: 18px;
}

.card-active {
    border-color: var(--fuse-primary);
    box-shadow: 0 6px 13px #c3c3e1bf;
    border-width: 1px;
}

.btn-active {
    border-color: var(--fuse-primary);
    border-width: 1px;
    border-radius: 20px;
    padding: 0 10px;
}

.mockup-code {
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    min-width: 18rem;
    --tw-bg-opacity: 1;
    background-color: hsl(var(--n)/var(--tw-bg-opacity));
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    --tw-text-opacity: 1;
    color: hsl(var(--nc)/var(--tw-text-opacity));
    border-radius: var(--rounded-box, 1rem)
}

.mockup-code:before {
    content: "";
    margin-bottom: 1rem;
    display: block;
    height: .75rem;
    width: .75rem;
    border-radius: 9999px;
    opacity: .3;
    box-shadow: 1.4em 0,2.8em 0,4.2em 0
}
code,kbd,samp,pre {
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-size: 1em
}

.prose pre code {
    border-radius: 0;
    padding: 0;
}
.mockup-code pre {
    padding-right: 1.25rem
}

.mockup-code pre:before {
    content: "";
    margin-right: 2ch
}

.mockup-window:before {
    content: "";
    margin-bottom: 1rem;
    display: block;
    height: .75rem;
    width: .75rem;
    border-radius: 9999px;
    opacity: .3;
    box-shadow: 1.4em 0,2.8em 0,4.2em 0
}

.mockup-code pre[data-prefix]:before {
    content: attr(data-prefix);
    display: inline-block;
    text-align: right;
    width: 2rem;
    opacity: .5
}

.mockup-window {
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    padding-top: 1.25rem;
    border-radius: var(--rounded-box, 1rem)
}

.mockup-window pre[data-prefix]:before {
    content: attr(data-prefix);
    display: inline-block;
    text-align: right
}
.loader-class {
    z-index: 100001;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    color: white;
    padding: 1.25rem;
    min-width: 120px;
    max-width: 200px;
    width: auto;
    height: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .loader-class p {
    color: white;
    text-align: center;
    margin: 0.5rem 0 0;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }
  
  .loader-class svg {
    width: 2rem;
    height: 2rem;
  }
  
  @media (max-width: 767px) {
    .loader-class {
      min-width: 100px;
      max-width: 160px;
      padding: 1rem;
    }
  
    .loader-class p {
      font-size: 0.75rem;
      max-width: 140px;
    }
  
    .loader-class svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
  
  @media (min-width: 768px) and (max-width: 1023px) {
    .loader-class {
      min-width: 110px;
      max-width: 180px;
    }
  }
.ww-break {
  word-wrap: break-word;
  width: 5%;
}

.wwbreak {
  word-wrap: break-word;
}

.iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    height: 100vh;
  }
  
  .iframe-container iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: -32px;
    width: 100%;
  }

input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #555;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
  }
  
//   input[type="checkbox"]:checked {
//     background-color: orange;
//   }
  
//   input[type="checkbox"]::before {
//     content: "";
//     display: block;
//     width: 20px;
//     height: 20px;
//     border: 2px solid #555;
//     border-radius: 4px;
//   }
  
//   input[type="checkbox"]:checked::before {
//     content: "";
//     display: block;
//     width: 20px;
//     height: 20px;
//     background-color: orange;
//     border: 2px solid #555;
//     border-radius: 4px;
//   }
  
//   input[type="checkbox"]::after {
//     content: "";
//     display: block;
//     position: relative;
//     top: -20px;
//     left: 2px;
//     width: 16px;
//     height: 16px;
//     border-radius: 2px;
//     background-color: orange;
//     box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
//   }
  
  input[type="checkbox"]:checked::after {
    content: "✔";
    font-size: 15px;
    color: black;
    background-color: orange;
    position: relative;
    top: -2px;
    left: 0px;
  }

  ::ng-deep button .mdc-button .mdc-button--unelevated .mat-mdc-unelevated-button .mat-primary .mat-mdc-button-base{
    border-radius: 0 !important;
    padding: 0 20px !important;
  }

// ::ng-deep .mat-mdc-button, .mat-mdc-raised-button, .mat-mdc-outlined-button, .mat-mdc-unelevated-button{
//     border-radius: 0;
//     padding: 0 20px !important;
//   }
  
::ng-deep .mat-button-base {
    border-radius: 0 !important;
  }
  
  ::ng-deep .mat-button-ripple {
    border-radius: 0 !important;
  }
  
  ::ng-deep .mat-raised-button {
    border-radius: 0 !important;
  }
  
